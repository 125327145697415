/* karla-400normal - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Karla Regular '),
    local('Karla-Regular'),
    url('./files/karla-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/karla-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* karla-400italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Karla Regular italic'),
    local('Karla-Regularitalic'),
    url('./files/karla-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/karla-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* karla-700normal - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Karla Bold '),
    local('Karla-Bold'),
    url('./files/karla-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/karla-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* karla-700italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Karla Bold italic'),
    local('Karla-Bolditalic'),
    url('./files/karla-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/karla-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

